@font-face {
  font-family: TruenoRg;
  src: url('../assets/font/TruenoRg.otf');
}

@font-face {
  font-family: TruenoBd;
  src: url('../assets/font/TruenoBd.otf');
}

@font-face {
  font-family: TruenoBlk;
  src: url('../assets/font/TruenoBlk.otf');
}

@font-face {
  font-family: TruenoLt;
  src: url('../assets/font/TruenoLt.otf');
}

@font-face {
  font-family: TruenoSBd;
  src: url('../assets/font/TruenoSBd.otf');
}