.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__input-container input {
  /* border: 1px solid red; */
  border-radius: 10px;
  background-color: #f1f2f6;
  height: 50px;
  width: 100%;
  outline: none;
  padding-left: 15px;
  padding-right: 15px;
}

.react-datepicker__input-container input::placeholder {
  color: black;
}
